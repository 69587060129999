import { useQueryClient } from "@tanstack/react-query";
import { QueryKey } from '../queries/keys'

const useUserInvalidate = () => {
    const queryClient = useQueryClient()
    const invalidate = () =>{
        queryClient.invalidateQueries({queryKey: [QueryKey.User]})
        queryClient.invalidateQueries({queryKey: [QueryKey.User, QueryKey.JobTitles]})
    }
    const eraseQuery = () => {
        queryClient.removeQueries({queryKey: [QueryKey.User]})
    }
    return {
        invalidate,
        eraseQuery
    }
}

export default useUserInvalidate;